import template from "./verpackung-editor.html.twig";

import {ErrorMessage, Field, Form} from "vee-validate";

Packlab.Component.register('verpackung-editor', {
    template,

    components: {Form, Field, ErrorMessage},

    mixins: [ 'form-validation', 'verpackung-editor' ],

    props: {
        ruleset: {
            type: Object,
            required: true
        },
        verpackungKombi: {
            type: Object,
            required: true
        },
        verpackungTyp: {
            type: Object,
            required: true
        },
        verpackungId: {
            type: [ String, null ],
            required: false,
            default: null
        },
    },

    computed: {
        verpackungTypGruppen() {
            return this.ruleset.getVerpackungTypGruppen();
        },
        mainVerpackung() {
            return this.verpackungTyp.getMainVerpackung();
        },
        komponenten() {
            return this.verpackungTyp.getKomponenten();
        },
        weitereKomponenten() {
            return this.verpackungTyp.getMainVerpackung().weitereKomponenten;
        },
        komponentenNames() {
            return this.ruleset.getKomponentenNames();
        },
        verpackungTypeGruppenPreviews() {
            const verpackungTypGruppenPreviews = [];
            const verpackungTypGruppen = this.ruleset.getVerpackungTypGruppen();
            for(let key in verpackungTypGruppen) {
                const verpackungTypGruppe = verpackungTypGruppen[key];
                try {
                    verpackungTypGruppenPreviews.push({
                        key: key,
                        title: verpackungTypGruppe.title,
                        image: require(`../../../../../../../assets/images/previewImages/${key.toLowerCase()}.jpg`)
                    });
                } catch(e) {}
            }
            return verpackungTypGruppenPreviews;
        }
    },

    date() {
        return {
            showCreateKombiModal: false
        };
    },

    methods: {
        getMaterialfraktionSelectBtnText(verpackung) {
            const verpackungTyp = verpackung.verpackungTyp;
            if(verpackungTyp.verpackungTypGruppeKey === 'custom') {
                return null;
            }
            if(!verpackungTyp.createKombi) {
                return null;
            }
            if(verpackungTyp.isKombi && verpackungTyp.deleteKombi) {
                return null;
            }
            const hauptKomponente = Object.values(verpackungTyp.verpackungTypGruppe.komponenten).filter((k) => { return k.kombiPart === verpackung.verpackungIndex && k.hauptKomponente; })[0] || null;
            if(hauptKomponente) {
                return this.$t('Wählen Sie eine Materialfraktion für') + ' ' + hauptKomponente.title;
            }
            return verpackung.name;
        },

        getVerpackungen(verpackungTyp) {
            return verpackungTyp.getEditableVerpackungen(this.verpackungId)
        },

        onCreateCustomVerpackungTyp({ materialFraktion, wertstoff }) {
            const verpackungTypGruppeKey = this.verpackungTyp.verpackungTypGruppeKey;
            const verpackungTypGruppe = this.ruleset.getVerpackungTypGruppe(verpackungTypGruppeKey);
            if(!verpackungTypGruppe) {
                return;
            }
            try {
                verpackungTypGruppe.key = verpackungTypGruppeKey;
                this.verpackungTyp.setVerpackungTypGruppe(this.ruleset, verpackungTypGruppe);
                this.verpackungTyp.setCustomVerpackungTyp(this.$t('Neue Verpackung'), materialFraktion, wertstoff);
            } catch(e) {
                console.error(e);
            }
        },

        verpackungMaterialfraktionSelectClosed() {
            this.verpackungTyp.verpackungTypGruppeKey = '';
        },

        onVerpackungTypGruppeChanged(verpackungTypGruppeKey) {
            const verpackungTypGruppe = this.ruleset.getVerpackungTypGruppe(verpackungTypGruppeKey);
            if(!verpackungTypGruppe) {
                return;
            }
            this.verpackungTyp.create({
                name: verpackungTypGruppe.title,
                verpackungTypGruppeKey: verpackungTypGruppeKey,
            });
            if(Object.values(verpackungTypGruppe.filterSelect).length > 0) {
                try {
                    verpackungTypGruppe.key = verpackungTypGruppeKey;
                    this.verpackungTyp.setVerpackungTypGruppe(this.ruleset, verpackungTypGruppe);
                } catch(e) {
                    console.error(e);
                }
                return;
            }

            this.$refs.verpackungMaterialfraktionSelect.onSelectMaterialFraktion();
        },

        onVerpackungFilterChanged(verpackungTyp, key, value) {
            let filterSelectFound = false;
            for(let filterSelectKey in verpackungTyp.filterSelects) {
                if(filterSelectKey === key) {
                    verpackungTyp.filterSelects[filterSelectKey].value = value;
                    filterSelectFound = true;
                } else if(filterSelectFound) {
                    verpackungTyp.filterSelects[filterSelectKey].value = '';
                }
            }

            verpackungTyp.wertstoff = null;
            verpackungTyp.selectWertstoff = false;
            verpackungTyp.getMainVerpackung().materialFraktion = null;
            verpackungTyp.getMainVerpackung().setWertstoff(null);

            verpackungTyp.updateFilterVisibility();
            verpackungTyp.updateAuswahlKomponenten(this.ruleset);
        },

        onWertstoffFilterChanged(verpackungTyp, wertstoff) {
            verpackungTyp.getMainVerpackung().setWertstoff(wertstoff, this.ruleset);
            verpackungTyp.updateAuswahlKomponenten(this.ruleset);
        },

        onResetVerpackungTyp(verpackungTyp) {
            verpackungTyp.create();
        },

        onChangeKomponente(komponente) {
            const createKombi = this.verpackungTyp.createKombi;
            this.verpackungTyp.updateVerpackungTyp();
            if(this.verpackungTyp.createKombi && this.verpackungTyp.createKombi != createKombi) {
                this.showCreateKombiModal = true;
            }
            if(komponente.selected) {
                komponente.addDefaultWertstoff();
            }
        },

        onAddWeitereKomponente(verpackung) {
            verpackung.createWeitereKomponente();
        },

        onChangeWeitereKomponente(verpackung, index) {
            const weitereKomponente = verpackung.weitereKomponenten[index];
            const selectedKomponenteTitle = this.komponentenNames[weitereKomponente.nameKey] ?? null;
            if(selectedKomponenteTitle) {
                weitereKomponente.isCustomInput = typeof selectedKomponenteTitle.customInput === "boolean" ? selectedKomponenteTitle.customInput : false;
            }
        },

        onRemoveWeitereKomponente(verpackung, index) {
            verpackung.removeWeitereKomponente(index);
        },

        onChangeMaterialFraktion(verpackung, selection) {
            verpackung.setWertstoff(selection.wertstoff);
        },

        verpackungFilterVisible(verpackungTyp, key) {
            if(key && typeof verpackungTyp.filterSelects[key] !== "undefined") {
                const filterSelect = verpackungTyp.filterSelects[key];
                return filterSelect.visibleCondition === true && filterSelect.options.length > 1;
            }
            return false;
        }
    }
});
