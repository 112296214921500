import {getNumberFormat, isEmpty} from "../../utils/utils";

Packlab.Mixin.register('form-validation', {
    computed: {
        formValidationValidators() {
            return {
                required: {
                    message: this.$t('Dieses Feld ist ein Pflichtfeld'),
                    validate: (value) => { return !isEmpty(value); }
                },
                colorRequired: {
                    message: this.$t('Dieses Feld ist ein Pflichtfeld'),
                    validate: (value) => { return !isEmpty(value) && value !== 'select'; }
                },
                maxInt: {
                    message: this.$t('Geben Sie bitte einen Wert kleiner oder gleich 4.294.967.295.'),
                    validate: (value) => { return this.parseFloat(value) < 4294967295; }
                },
                minValue: {
                    message: this.$t('Geben Sie bitte einen Wert größer oder gleich {value} ein.'),
                    validate: (value, minValue) => { return this.parseFloat(value) >= minValue; }
                },
                maxValue: {
                    message: this.$t('Geben Sie bitte einen Wert kleiner oder gleich {value} ein.'),
                    validate: (value, maxValue) => { return this.parseFloat(value) <= maxValue; }
                },
                gtValue: {
                    message: this.$t('Geben Sie bitte einen Wert größer als {value} ein.'),
                    validate: (value, minValue) => { return this.parseFloat(value) > minValue; }
                },
                ltValue: {
                    message: this.$t('Geben Sie bitte einen Wert kleiner als {value} ein.'),
                    validate: (value, maxValue) => { return this.parseFloat(value) < maxValue; }
                },
                rangeValue: {
                    message: this.$t('Bitte geben Sie einen Wert zwischen {minValue} und {maxValue} ein.'),
                    validate: (value, range) => {  return this.parseFloat(value) >= range.minValue && this.parseFloat(value) <= range.maxValue; }
                },
                minLength: {
                    message: this.$t('Geben Sie bitte mindestens {value} Zeichen ein.'),
                    validate: (value, minLength) => { return this._getStrLength(value) >= minLength; }
                },
                maxLength: {
                    message: this.$t('Geben Sie bitte maximal {value} Zeichen ein.'),
                    validate: (value, maxLength) => { return this._getStrLength(value) <= maxLength; }
                },
                rangeLength: {
                    message: this.$t('Geben Sie bitte mindestens {minLength} und maximal {maxLength} Zeichen ein.'),
                    validate: (value, range) => {
                        const strLength = this._getStrLength(value);
                        return strLength >= range.minLength && strLength <= range.maxLength;
                    }
                },
                email: {
                    message: this.$t('Geben Sie bitte eine gültige E-Mail-Adresse ein.'),
                    validate: (value) => { return this.isValidEmail(value); },
                    maxLength: 100
                },
                equalTo: {
                    message: this.$t('Wiederholen Sie bitte denselben Wert.'),
                    validate: this.isEqualsTo.bind(this)
                },
                equalEmail: {
                    message: this.$t('Bitte bestätigen Sie Ihre E-Mail'),
                    validate: this.isEqualsTo.bind(this)
                },
                repeatEmail: {
                    message: this.$t('Bitte wiederholen Sie Ihre E-Mail'),
                    validate: this.isEqualsTo.bind(this)
                },
                depositObligationThreshold: {
                    message: this.$t('Bitte geben Sie einen Wert zwischen 50 und 95 % ein'),
                    validate: (value) => {
                        /*return value >= 90 && value <= 95;*/
                        return value >= 50 && value <= 95;
                    }
                },
                fundContribution: {
                    message: this.$t('Bitte geben Sie einen Wert zwischen 50 und 2000 EUR ein'),
                    validate: (value) => {
                        /*return value >= 200 && value <= 300;*/
                        return value >= 50 && value <= 20000;
                    }
                }
            }
        },
        formFieldValidationRules() {
            return {
                customerLogin: {
                    email: {
                        required: true,
                        email: true,
                        maxLength: 200
                    },
                    password: {
                        required: true,
                        maxLength: 100
                    }
                },
                passwordChange: {
                    password: {
                        required: true,
                        maxLength: 100
                    },
                    newEmail: {
                        required: true,
                        email: true,
                        maxLength: 200
                    },
                    newEmail2: {
                        required: true,
                        email: true,
                        repeatEmail: 'newEmail'
                    }
                },
                fundSettings: {
                    depositObligationThreshold: {
                        required: true,
                        depositObligationThreshold: true,
                        minValue: 90,
                        maxValue: 95
                    },
                    fundContribution: {
                        required: true,
                        fundContribution: true,
                        minValue: 50,
                        maxValue: 2000
                    }
                },
                customer: {
                    phone: {
                        required: true,
                        maxLength: 40
                    },
                    businessYesno: {
                        required: true
                    },
                    name: {
                        required: true,
                        maxLength: 255
                    },
                    vatId: {
                        maxLength: 30
                    },
                    taxNumber: {
                        maxLength: 30
                    }
                },
                customerAccount: {
                      salutationEnum: {
                          required: true
                      },
                      firstname: {
                          required: true,
                          maxLength: 50
                      },
                      lastname: {
                          required: true,
                          maxLength: 60
                      },
                      email: {
                          required: true,
                          email: true,
                          maxLength: 200
                      },
                      email2: {
                          required: true,
                          email: true,
                          equalEmail: 'email'
                      },
                },
                customerAddress: {
                    zip: {
                        required: true,
                        maxLength: 50
                    },
                    city: {
                        required: true,
                        maxLength: 70
                    },
                    countryId: {
                        required: true
                    },
                    street: {
                        required: true,
                        maxLength: 255
                    },
                    address1: {
                        maxLength: 255
                    },
                    company: {
                        maxLength: 255
                    }
                },
                verpackung: {
                    materialFraktion: {
                        required: true
                    },
                    totalVerpackungen: {
                        required: true,
                        maxInt: true
                    },
                    quantity: {
                        required: true,
                        minValue: 1,
                        maxInt: true
                    },
                    name: {
                        required: true,
                        maxLength: 255
                    },
                    ean: {
                        maxLength: 100
                    },
                    dimensions: {
                        maxLength: 100
                    }
                },
                verpackungKombi: {
                    name: {
                        required: true,
                        maxLength: 255
                    },
                    ean: {
                        maxLength: 100
                    },
                },
                verpackungGruppe: {
                    name: {
                        required: true,
                        maxLength: 255
                    },
                    gruppeName:  {
                        required: true,
                        maxLength: 255
                    }
                },
                komponenteWertstoff: {
                    wertstoff: {
                        required: true
                    },
                    color: {
                        colorRequired: true
                    },
                    weight: {
                        required: true,
                        gtValue: 0
                    },
                    rezyclatquote: {
                        required: true,
                        rangeValue: {
                            minValue: 0,
                            maxValue: 100
                        }
                    },
                },
                komponenteWertstoffData: {
                    faserstoffanteil: {
                        required: true,
                        rangeValue: {
                            minValue: 0,
                            maxValue: 100
                        }
                    },
                    anteilFarbe: {
                        required: true,
                        rangeValue: {
                            minValue: 0,
                            maxValue: 100
                        }
                    },
                    glasEtikett: {
                        required: true,
                        rangeValue: {
                            minValue: 0,
                            maxValue: 100
                        }
                    },
                    wasserloeslicheBestandteile: {
                        required: true,
                        rangeValue: {
                            minValue: 0,
                            maxValue: 100
                        }
                    },
                    transmissionsgrad10: {
                        required: true
                    }
                },
                zertifikatContactPerson: {
                    contactPerson: {
                        required: true
                    },
                    contactEmail: {
                        required: true,
                        email: true,
                        maxLength: 100
                    }
                }
            };
        }
    },

    methods: {
        isRequiredField(value) {
            if(value === null || value === undefined || value === '') {
                return this.getFormValidationRequiredMessage();
            }
            return true;
        },
        isRequiredCheckbox(value) {
            if(!value) {
                return this.getFormValidationRequiredMessage();
            }
            return true;
        },
        isEqualsTo(value, fieldName, entity) {
            return value == (typeof entity === 'object' ? entity[fieldName] : entity);
        },
        isRequiredEmailField(value) {
            if(value === null || value === undefined || value === '') {
                return this.getFormValidationRequiredMessage();
            }
            if(!this.isValidEmail(value)) {
                return this.getFormValidationEmailMessage();
            }
            return true;
        },
        getFormValidationRequiredMessage() {
            return this.$t('Dieses Feld ist ein Pflichtfeld.');
        },
        getFormValidationEmailMessage() {
            return this.$t('Bitte geben Sie eine gültige E-Mail ein');
        },
        isValidEmail(email) {
            return email.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
        },
        setCustomFormErrors(form, errors) {
            for(let e in errors) {
                const error = errors[e];
                const code = error.code;
                let pointer = error?.source?.pointer;
                let message = error.detail;
                if(!pointer || !code) {
                    continue;
                }
                if(code == 'c1051bb4-d103-4f74-8988-acbcafc7fdc3') {
                    message = this.getFormValidationRequiredMessage();
                }
                form.setFieldError(this._getPointerPath(pointer), message);
            }
        },

        isFieldRequired(objectName, fieldName) {
            const fieldValidationRules = this._getFieldValidationRules(objectName, fieldName);
            return fieldValidationRules.required || false;
        },
        parseFloat(value) {
            if (typeof value === null || typeof value === undefined) {
                return 0.0;
            }
            if (typeof value === 'number') {
                return value;
            }
            const numberFormat = getNumberFormat(this.$i18n.locale);
            value = value.replace(new RegExp(`[${numberFormat.digitGroupSeparator}]`, 'g'), '');
            value = value.replace(new RegExp(`[${numberFormat.decimalCharacter}]`, 'g'), '.');
            return parseFloat(value);
        },

        buildFormValidationRules(objectName, fieldName, entity = null, options = {}) {
            const formFieldValidators = this._getFormFieldValidators(objectName, fieldName);
            const customMessage = options.customMessage || null;
            return (value, meta) => {
                this._resetValidationReport(entity, formFieldValidators, fieldName);
                for(let rule in formFieldValidators) {
                    const formFieldValidator = formFieldValidators[rule];
                    if(formFieldValidator.callback) {
                        const message = formFieldValidator.callback(value, rule, entity);
                        if(message !== true) {
                            this._setValidationReport(entity, fieldName, rule, message, false);
                            return message;
                        }
                    } else {
                        if(!formFieldValidator.validate(value, formFieldValidator.options, entity, rule)) {
                            const messagePlaceholders = typeof formFieldValidator.options === 'object' ? formFieldValidator.options : { value: formFieldValidator.options };
                            const message = this._replacePlaceholders(customMessage || formFieldValidator.message, messagePlaceholders);
                            this._setValidationReport(entity, fieldName, rule, message);
                            return message;
                        }
                    }

                }
                return true;
            };
        },

        buildFormValidationAttributes(objectName, fieldName, rules = null) {
            const formValidationAttributes = {};
            const formFieldValidators = this._getFormFieldValidators(objectName, fieldName);

            if(rules) {
                formValidationAttributes.rules = rules;
            }

            for(let validator in formFieldValidators) {
                const options = formFieldValidators[validator].options;
                switch(validator) {
                    case 'minValue':
                        formValidationAttributes.min = options;
                        break;
                    case 'maxValue':
                        formValidationAttributes.max = options;
                        break;
                }
            }

            return formValidationAttributes;
        },

        _getPointerPath(pointer) {
            pointer = (pointer.startsWith('/') ? pointer.slice(1) : pointer);
            pointer = pointer.split('/').join(' ');
            return pointer.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
                return index === 0 ? word.toLowerCase() : word.toUpperCase();
            }).replace(/\s+/g, '');
        },

        _getFieldValidationRules(objectName, fieldName) {
            if(typeof this.formFieldValidationRules[objectName] === 'undefined') {
                return {};
            }
            if(typeof this.formFieldValidationRules[objectName][fieldName] === 'undefined') {
                return {};
            }
            return this.formFieldValidationRules[objectName][fieldName];
        },

        _replacePlaceholders(text, placeholders) {
            for (let name in placeholders) {
                text = text.replaceAll('{' + name + '}', placeholders[name]);
            }
            return text;
        },

        _getStrLength(value) {
            if(value === undefined || value === null) {
                return 0;
            }
            if(typeof value !== 'string') {
                value = value.toString();
            }
            return value.length;
        },

        _getFormFieldValidators(objectName, fieldName) {
            const fieldValidationRules = this._getFieldValidationRules(objectName, fieldName);
            const formFieldValidators = {};
            for(let rule in fieldValidationRules) {
                const options = fieldValidationRules[rule];
                if(typeof options === 'function') {
                    formFieldValidators[rule] = {
                        callback: options
                    };
                    continue
                }
                const formValidationValidator = this.formValidationValidators[rule] || null;
                if (!formValidationValidator) {
                    console.warn('form-validation-validator not found!', {objectName, fieldName, rule});
                    continue;
                }
                formFieldValidators[rule] = {
                    message: formValidationValidator.message,
                    validate: formValidationValidator.validate,
                    options: fieldValidationRules[rule]
                };
            }
            return formFieldValidators;
        },

        _resetValidationReport(entity, formFieldValidators, fieldName) {
            if(!entity) {
                return;
            }

            const validationReportField = this._getOrCreateValidationReportField(entity, fieldName);
            validationReportField.valid = true;
            validationReportField.message = '';
            validationReportField.rules = {};

            for(let rule in formFieldValidators) {
                validationReportField.rules[rule] = true;
            }
        },

        _setValidationReport(entity, fieldName, rule, message, valid = false) {
            if(!entity) {
                return;
            }
            const validationReportField = this._getOrCreateValidationReportField(entity, fieldName);

            validationReportField.valid = valid;
            validationReportField.message = message;
            validationReportField.rules[rule] = valid;
        },

        _getOrCreateValidationReportField(entity, fieldName) {
            let validationReport = entity.validationReport || null;
            let validationReportField = null;
            if(!validationReport) {
                entity.validationReport = {};
                validationReport = entity.validationReport;
            }
            validationReportField = validationReport[fieldName] || null;
            if(!validationReportField) {
                validationReport[fieldName] = {
                    valid: true,
                    message: ''
                };
                validationReportField = validationReport[fieldName];
            }
            return validationReportField;
        }
    }
});

/*
$.extend( $.validator.messages, {

	url: "Geben Sie bitte eine gültige URL ein.",
	date: "Geben Sie bitte ein gültiges Datum ein.",
	number: "Geben Sie bitte eine Nummer ein.",
	digits: "Geben Sie bitte nur Ziffern ein.",
	equalTo: "Wiederholen Sie bitte denselben Wert.",
	range: $.validator.format( "Geben Sie bitte einen Wert zwischen {0} und {1} ein." ),
	max: $.validator.format( "Geben Sie bitte einen Wert kleiner oder gleich {0} ein." ),
	min: $.validator.format( "Geben Sie bitte einen Wert größer oder gleich {0} ein." ),
	creditcard: "Geben Sie bitte eine gültige Kreditkarten-Nummer ein.",
	remote: "Korrigieren Sie bitte dieses Feld.",
	dateISO: "Geben Sie bitte ein gültiges Datum ein (ISO-Format).",
	step: $.validator.format( "Geben Sie bitte ein Vielfaches von {0} ein." ),
	maxWords: $.validator.format( "Geben Sie bitte {0} Wörter oder weniger ein." ),
	minWords: $.validator.format( "Geben Sie bitte mindestens {0} Wörter ein." ),
	rangeWords: $.validator.format( "Geben Sie bitte zwischen {0} und {1} Wörtern ein." ),
	accept: "Geben Sie bitte einen Wert mit einem gültigen MIME-Typ ein.",
	alphanumeric: "Geben Sie bitte nur Buchstaben (keine Umlaute), Zahlen oder Unterstriche ein.",
	bankaccountNL: "Geben Sie bitte eine gültige Kontonummer ein.",
	bankorgiroaccountNL: "Geben Sie bitte eine gültige Bank- oder Girokontonummer ein.",
	bic: "Geben Sie bitte einen gültigen BIC-Code ein.",
	cifES: "Geben Sie bitte eine gültige CIF-Nummer ein.",
	cpfBR: "Geben Sie bitte eine gültige CPF-Nummer ein.",
	creditcardtypes: "Geben Sie bitte eine gültige Kreditkarten-Nummer ein.",
	currency: "Geben Sie bitte eine gültige Währung ein.",
	extension: "Geben Sie bitte einen Wert mit einer gültigen Erweiterung ein.",
	giroaccountNL: "Geben Sie bitte eine gültige Girokontonummer ein.",
	iban: "Geben Sie bitte eine gültige IBAN ein.",
	integer:  "Geben Sie bitte eine positive oder negative Nicht-Dezimalzahl ein.",
	ipv4: "Geben Sie bitte eine gültige IPv4-Adresse ein.",
	ipv6: "Geben Sie bitte eine gültige IPv6-Adresse ein.",
	lettersonly: "Geben Sie bitte nur Buchstaben ein.",
	letterswithbasicpunc: "Geben Sie bitte nur Buchstaben oder Interpunktion ein.",
	mobileNL: "Geben Sie bitte eine gültige Handynummer ein.",
	mobileUK: "Geben Sie bitte eine gültige Handynummer ein.",
	netmask:  "Geben Sie bitte eine gültige Netzmaske ein.",
	nieES: "Geben Sie bitte eine gültige NIE-Nummer ein.",
	nifES: "Geben Sie bitte eine gültige NIF-Nummer ein.",
	nipPL: "Geben Sie bitte eine gültige NIP-Nummer ein.",
	notEqualTo: "Geben Sie bitte einen anderen Wert ein. Die Werte dürfen nicht gleich sein.",
	nowhitespace: "Kein Leerzeichen bitte.",
	pattern: "Ungültiges Format.",
	phoneNL: "Geben Sie bitte eine gültige Telefonnummer ein.",
	phonesUK: "Geben Sie bitte eine gültige britische Telefonnummer ein.",
	phoneUK: "Geben Sie bitte eine gültige Telefonnummer ein.",
	phoneUS: "Geben Sie bitte eine gültige Telefonnummer ein.",
	postalcodeBR: "Geben Sie bitte eine gültige brasilianische Postleitzahl ein.",
	postalCodeCA: "Geben Sie bitte eine gültige kanadische Postleitzahl ein.",
	postalcodeIT: "Geben Sie bitte eine gültige italienische Postleitzahl ein.",
	postalcodeNL: "Geben Sie bitte eine gültige niederländische Postleitzahl ein.",
	postcodeUK: "Geben Sie bitte eine gültige britische Postleitzahl ein.",
	require_from_group: $.validator.format( "Füllen Sie bitte mindestens {0} dieser Felder aus." ),
	skip_or_fill_minimum: $.validator.format( "Überspringen Sie bitte diese Felder oder füllen Sie mindestens {0} von ihnen aus." ),
	stateUS: "Geben Sie bitte einen gültigen US-Bundesstaat ein.",
	strippedminlength: $.validator.format( "Geben Sie bitte mindestens {0} Zeichen ein." ),
	time: "Geben Sie bitte eine gültige Uhrzeit zwischen 00:00 und 23:59 ein.",
	time12h: "Geben Sie bitte eine gültige Uhrzeit im 12-Stunden-Format ein.",
	vinUS: "Die angegebene Fahrzeugidentifikationsnummer (VIN) ist ungültig.",
	zipcodeUS: "Die angegebene US-Postleitzahl ist ungültig.",
	ziprange: "Ihre Postleitzahl muss im Bereich 902xx-xxxx bis 905xx-xxxx liegen."
} );
 */