import template from "./fonds-beitrag-overview-page.html.twig";

Packlab.Component.register('fonds-beitrag-overview-page', {
    template,

    inject: [ 'apiClient' ],

    props: {

    },

    data() {
        return {
            isLoading: false,
            settings: null, 
            verpackungen: null,
            depositObligationThreshold: null,
            fundContribution: 0,
            fundContribution_total: 0,

            vp_anzahl_total: 0,
            vp_anzahl_relevant: 0,
            vp_anzahl_fond: 0,
            vp_anzahl_nofond: 0,
            vp_weight_total: 0,
            vp_weight_fond: 0,

            vp_verkehr_anzahl_total: 0,
            vp_verkehr_weight_total: 0,

            vp_no_info: [],

            chartData: [],
            chartItems: 0,
            chartBar: 0,
            chartGap: 0

        }
    },
    
    computed: {

        checkVerpackungen() {
            //return this.verpackungen

            try {

                
                for (let vp in this.verpackungen) {

                //console.log("verpackung", this.verpackungen[vp]);
                    let verpackung = this.verpackungen[vp];
                    let quote = verpackung.recyclingQuote;

                    let name = verpackung.name.replace(/[^\w ]/g, '');
                    let vpid = verpackung.id;

                    let fundContribution = verpackung.fundContribution;
                    let totalVerpackungen = verpackung.totalVerpackungen;
                    let totalVerpackungenWeight = verpackung.totalVerpackungenWeight;
                    let color = "#e2001a"; // do not change or check mouseover function below

                    this.vp_anzahl_total++;

                    if (parseInt(verpackung.bmg.status) > 8101) {

                        this.vp_anzahl_relevant++;

                        if (quote < this.depositObligationThreshold) {

                            this.vp_anzahl_fond++;


                            if (totalVerpackungen > 0) {

                                this.vp_verkehr_anzahl_total += totalVerpackungen;
                                this.vp_verkehr_weight_total += totalVerpackungenWeight;
                                this.fundContribution_total += fundContribution;

                            }

                        } else {
                            color = "#6f9a18";// do not change or check mouseover function below
                            this.vp_anzahl_nofond++;

                        }
                        quote = quote > 0 ? quote : 1;
                        this.chartData.push([quote, totalVerpackungen, totalVerpackungenWeight / 1000, fundContribution, color, vpid, name]);

                    }

                    if (!totalVerpackungen) {
                        this.vp_no_info.push([verpackung.id, verpackung.name]);
                    }
                }
                
                
                let items = this.chartData.length;

            
                let gap = 560 / items * 0.05
                this.chartItems = items;
                this.chartGap = gap;
                this.chartBar = 560 / items - gap;
            } catch(e) {
                console.error(e);
            }
      
        }
    },



    created() {
        this.loadVerpackungen();
        
    },

    methods: {
        mouseover(e, bar){
            //let chartPos = document.querySelector("#fond-chart").style.offsetWidth;
            let label = document.querySelector("#fond-chart-label");
            let labelName =  document.querySelector("#fond-chart-label-name");
            if(bar) {
                let name = bar.dataset.vpname;
                let vpid = bar.dataset.vpid;
                if(vpid && name){
                    labelName.innerHTML = name;
                    
                    var left  = 25;//e.clientX - chartPos;
                    var top  = 15;//e.clientY;
                   
                    label.style.left = left + "px";
                    label.style.top = top + "px";
                    bar.style.cursor="pointer";

                    label.classList.add("active");

                }
            }
        },
        mouseout(bar) {
            let label = document.querySelector("#fond-chart-label");
            if(label){
                label.classList.remove("active");
            }
        },

        onclick(bar) {
            
            if(bar) {
                let vpid = bar.dataset.vpid;
                if(vpid) {
                    this.$router.push(`verpackung/${vpid}`);
                }
            }
        },
        loadVerpackungen() {
            this.isLoading = true;
            this.apiClient.getCustomerSettings()
                .then((settingsResult) => {
                    this.settings = settingsResult.settings;
                    this.fundContribution = this.settings.fundContribution;
                    this.depositObligationThreshold = this.settings.depositObligationThreshold
                    this.apiClient.getVerpackungen({ })
                    .then((result) => {
                        this.verpackungen = result.verpackungen;
                        this.isLoading = false;
                    })
                    .then(()=>
                        this.checkVerpackungen()
                    )
                   
                    .catch(() => { this.isLoading = false; });
                })
                .then(()=>
                    this.barChart()
                ) 
                .catch(() => { this.isLoading = false; });
            
        }
    }


});