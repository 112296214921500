import template from "./verpackung-wizard.html.twig";
import Verpackung from "../../../../model/verpackung/verpackung";
import { Form, ErrorMessage } from "vee-validate";
import VerpackungKombi from "../../../../model/verpackung/verpackung-kombi";

Packlab.Component.register('verpackung-wizard', {
    template,
    emits: [ 'update:verpackungen', 'saved' ],
    components: { Form, ErrorMessage },

    mixins: [ 'form-validation', 'verpackung-editor' ],
    inject: [ 'apiClient', 'verpackungService'],

    props: {
        verpackungId: {
            type: [ String, null ],
            required: false,
            default: null
        }
    },

    computed: {
        verpackungImages() {
            return Packlab.Config.verpackungImages;
        },
        hasSelectedVerpackungTyp() {
            const verpackungTypen = this.verpackungKombi?.verpackungTypen;
            if(!verpackungTypen) {
                return verpackungTypen;
            }
            const verpackungTyp = verpackungTypen[0] || null;
            if(!verpackungTyp) {
                return false;
            }
            return verpackungTyp.verpackungTypKey != null;
        }
    },

    data() {
        return {
            isLoading: false,
            isSaving: false,
            createKombi: false,
            createGruppe: false,
            currentWizardStep: 'verpackung',
            verpackungenErrors: null,
            ruleset: null,
            verpackungKombi: null,
            wizardSteps: [
                {
                    key: 'verpackungKombi'
                },
                {
                    key: 'verpackung'
                },
                {
                    key: 'komponenten'
                }
            ]
        }
    },

    created() {
        this.registerDumpWatcher('verpackungKombi');
        this.createKombi = this.$route.query.createKombi !== undefined;
        this.createGruppe = this.$route.query.createGruppe !== undefined;
        if(this.createKombi || this.createGruppe) {
            this.currentWizardStep = 'verpackungKombi';
        }
        this.loadVerpackungWizard();
    },

    methods: {
        loadVerpackungWizard() {
            this.isLoading = true;
            if(this.verpackungId) {
                this.verpackungService.loadVerpackung(this.verpackungId)
                    .then((result) => {
                        this.ruleset = result.ruleset;
                        this.verpackungKombi = result.verpackungKombi;
                        this.isLoading = false;
                    })
                    .catch((e) => {
                        console.error(e);
                        this.isLoading = false;
                    });
            } else if(this.$route.query.kombiVerpackungId) {
                this.apiClient.getKombiVerpackung(this.$route.query.kombiVerpackungId)
                    .then((result) => {
                        this.loadRuleset().then(() => {
                            this.verpackungKombi = new VerpackungKombi();
                            this.verpackungKombi.create({
                                id: this.$route.query.kombiVerpackungId,
                                verpackungId: this.verpackungId,
                                isKombi: true,
                                createKombi: false,
                                name: result.verpackungKombi.name
                            });
                            this.isLoading = false;
                        })
                        .catch(() => {
                            this.isLoading = false;
                        });

                    })
                    .catch(() => {
                        this.isLoading = false;
                    });
            } else {
                this.loadRuleset()
                    .then(() => {
                        try {
                            let verpackungTypOptions = null;
                            this.verpackungKombi = new VerpackungKombi();
                            if (this.$route.query.createKombi !== undefined) {
                                verpackungTypOptions = [
                                    {
                                        isKombiDraft: true,
                                        name: this.$t('Verpackung 1')
                                    },
                                    {
                                        isKombiDraft: true,
                                        name: this.$t('Verpackung 2')
                                    }
                                ];
                            }
                            this.verpackungKombi.create({
                                id: null,
                                createKombi: this.$route.query.createKombi !== undefined,
                                createGruppe: this.$route.query.createGruppe !== undefined,
                                name: '',//this.$t('Neue Kombinationsverpackung')
                                verpackungTypen: verpackungTypOptions
                            });
                            this.isLoading = false;
                        } catch(e) {
                            console.error(e);
                        }
                    })
                    .catch(() => {
                        this.isLoading = false;
                    });
            }

        },
        loadRuleset(id = 0) {
            this.isLoading = true;
            return this.apiClient.getVerpackungRuleset(id)
                .then((ruleset) => {
                    this.ruleset = ruleset;
                });
        },
        validateVerpackung(onGoToNextStep) {
            this.$refs.form.validate().then((result) => {
            });
            this.verpackungenErrors = this.verpackungKombi.validate({ materialFraktion: true, komponenten: true, verpackungId: this.verpackungId });
            if(Object.values(this.verpackungenErrors).length > 0) {
                this.$refs.verpackungErrorModal.show();
            } else {
                onGoToNextStep();
            }
        },
        saveVerpackung(e) {
            e.preventDefault();
            this.isSaving = true;
            this.verpackungenErrors = null;

            this.$refs.komponentenWizardStep.validate().then((result) => {
                if(result.valid) {
                    this.$refs.komponentenWizardStep.save().then(() => {
                        this.verpackungService.saveVerpackungKombi(this.verpackungKombi)
                            .then((result) => {
                                this.$emit('saved');
                                this.isSaving = false;
                                if(result.goto === 'kombi-detail') {
                                    this.$router.push(`/kombi-verpackung/${result.id}`);
                                } else if(result.goto === 'verpackung-detail') {
                                    this.$router.push(`/verpackung/${result.id}`);
                                }
                            })
                            .catch(() => { this.isSaving = false; });
                    });

                } else {
                    this.verpackungenErrors = this._getverpackungenErrors(result.verpackungKombiResult);
                    this.isSaving = false;
                    this.$refs.verpackungErrorModal.show();
                }
            });
        },
        nextStepValidateHandler({ currentStep }) {
            return new Promise((resolve, reject) => {
                this.$refs.form.validate().then((result) => {
                    resolve(result.valid);
                });
            });
        },
        resetVerpackungTyp() {
            const verpackungTypen = this.verpackungKombi?.verpackungTypen;
            if(!verpackungTypen) {
                return;
            }
            const verpackungTyp = verpackungTypen[0] || null;
            if(!verpackungTyp) {
                return;
            }
            verpackungTyp.create();
        },
        _getverpackungenErrors(verpackungenResult) {
            const verpackungenErrors = [];

            return verpackungenErrors;
        }
    }
});