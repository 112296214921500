import template from "./packlab-app.html.twig";

Packlab.Component.register('packlab-app', {
    template: template,

    inject: [ 'apiClient', 'whitelabel' ],

    watch: {
        async '$route'() {
            this._loadWhitelabel();
        }
    },

    data() {
        return {
            pageLoaded: false,
            isLoadingContext: false,
            context: null,
            customer: null,
            selectedLanguage: null,
            logo: null,
            showLogout: false
        }
    },

    computed: {
        showSidebar() {
            return !this.isLoadingContext && this.customer && this.customer.active;
        },
        isTestContract() {
            if(this.isLoadingContext) {
                return true;
            }
            if(!this.context) {
                return true;
            }
            if(!this.context.contract) {
                return true;
            }
            return this.context.contract.contractType == 2820;
        },
        showAccounts() {
            if(this.isLoadingContext) {
                return false;
            }
            if(!this.context) {
                return false;
            }
            if(this.context.whitelabel) {
                return false;
            }
            return true;
        },
        languages() {
            return [
                {
                    code: 'de-DE',
                    label: 'DE'
                },
                /*{
                    code: 'en-GB',
                    label: 'EN'
                }*/
            ];
        }
    },

    created() {
        this._setupWhitelabel();
        this._updateSelectedLanguage();
        //Packlab.Emitter.on('context.loaded', this._onContextLoaded.bind(this));
        Packlab.Emitter.on('customer.loggedin', this._updateContext.bind(this));
        Packlab.Emitter.on('apiContextToken.expired', this._onCustomerLoggedout.bind(this));
        Packlab.Emitter.on('customer.loggedout', this._onCustomerLoggedout.bind(this));
    },

    methods: {
        onLogout() {
            this.apiClient.customerLogout().then(() => {
                this.context = null;
                this.$router.push({ name: 'index' });
            });
        },
        onNavClick(){
            window.scrollTo(0, 0);
        },
        toggleNav(){
            let sidebarNav = document.querySelector('#pl-main-content');
            if(sidebarNav.classList.contains('mini')) {
                sidebarNav.classList.remove("mini")
            } else {
                sidebarNav.classList.add("mini")
            }
        },
        onChangeLanguage(language) {
            Packlab.Application.setLocale(language.code);
            this.selectedLanguage = language;
            window.location.reload();
        },
        _onContextLoaded(context) {
        },
        _updateContext() {
            this.isLoadingContext = true
            this.apiClient.getContext().then((context) => {
                this.context = context;
                this.customer = context.customer;
                this.isLoadingContext = false;
                this.showLogout = context.whitelabel?.key != 'markant';
                this._loadWhitelabelStyles(context.whitelabel?.key);
            }).catch(() => {
                this.isLoadingContext = false;
            });
        },
        _updateSelectedLanguage() {
            for(let l in this.languages) {
                if(this.languages[l].code === Packlab.Application.getLocale()) {
                    this.selectedLanguage = this.languages[l];
                }
            }
        },
        _onCustomerLoggedout() {
            this.customer = null;
        },
        _loadWhitelabel() {
            if(this.pageLoaded) {
                return;
            }
            this.whitelabel.resolveWhitelabel(this.$route).then((result) => {
                this._finalizeApp();
            });
        },
        _loadWhitelabelStyles(whitelabelKey) {
            if(!whitelabelKey) {
                return;
            }
            try {
                import(`../../../../../styles/whitelabel/${whitelabelKey}/main.scss`);
            } catch(e) { }
        },
        _setupWhitelabel() {
            this.$store.subscribe((mutation, state) => {
                if(mutation.type != 'setWhitelabel') {
                    return;
                }
                const whitelabel = state.whitelabel || null;
                if(!whitelabel) {
                    return;
                }
                this.logo = whitelabel.logoUrl || null;
                if(whitelabel.faviconUrl) {
                    const favicon = document.querySelector("link[rel~='icon']");
                    if(favicon) {
                        favicon.setAttribute('href', whitelabel.faviconUrl);
                    }
                }
            });

            this.$router.beforeEach((to, from, next) => {
                const whitelabelKey = this.whitelabel.getWhitelabel()?.key;
                const toWhitelabelKey = to.params.whitelabelKey || null;
                if(whitelabelKey && !toWhitelabelKey) {
                    to.params.whitelabelKey = whitelabelKey;
                    next(to);
                    return;
                }
                next();
            })
        },
        _finalizeApp(updateContext = true) {
            if(updateContext) {
                this._updateContext();
            }
            this.pageLoaded = true;
        }
    },
    ready() {
        onNavClick();
    }
});