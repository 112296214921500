import template from "./verpackung-fond-calculator.html.twig";
import {ErrorMessage, Field, Form} from "vee-validate";

Packlab.Component.register('verpackung-fond-calculator', {
    template,
    emits: [ 'verpackung-loaded', 'start-save', 'saved' ],

    components: { Form, Field, ErrorMessage },
    mixins: [ 'form-validation' ],
    inject: [ 'apiClient' ],

    props: {
        verpackungId: {
            type: String,
            required: true
        },
        showHeader: {
            type: Boolean,
            required: false,
            default: true
        },
        showSaveBtn: {
            type: Boolean,
            required: false,
            default: true
        },
        col1Class: {
            type: String,
            required: false,
            //default: 'col-6 col-lg-3'
            default: 'col-8 col-lg-6 col-xl-4'
        },
        col2Class: {
            type: String,
            required: false,
            //default: 'col-6 col-lg-6'
            default: 'col-4 col-lg-3 col-xl-2 text-end'
        },
        col3Class: {
            type: String,
            required: false,
            default: null
        },
    },

    watch: {
        verpackungId() {
            this.loadVerpackung();
        }
    },

    data() {
        return {
            isLoading: false,
            isSaving: false,
            totalVerpackungen: 0,
            editTotalVerpackungen: false,
            verpackung: null,
            fundSettings: null
        }
    },

    created() {
        this.loadVerpackung();
        this.loadFundSettings();
    },

    methods: {
        toggleEditTotalVerpackungen() {
            if(this.editTotalVerpackungen) {
                this.verpackung.totalVerpackungen = this.totalVerpackungen;
                this.editTotalVerpackungen = false;
            } else {
                this.totalVerpackungen = this.verpackung.totalVerpackungen;
                this.editTotalVerpackungen = true;
            }
        },
        loadFundSettings() {
            this.apiClient.getCustomerSettings()
                .then((result) => {
                    this.fundSettings = result.settings;
                })
                .catch(() => { });
        },
        loadVerpackung() {

            this.apiClient.getContext().then((context) => {
                if(context?.contract?.calculateFundContributionYesno != 40) {
                    return;
                }
                this.isLoading = true;
                this.apiClient.getVerpackung(this.verpackungId, false)
                    .then((result) => {
                        try {
                            this.verpackung = result.verpackungTyp.verpackungen.filter((part) => part.id == this.verpackungId)[0] || null;
                            this.isLoading = false;
                            this.$emit('verpackung-loaded', this.verpackung);
                        } catch(e) {
                            console.error(e);
                        }
                    })
                    .catch(() => {
                        this.isLoading = false;
                    })
            });

        },
        save() {
            this.isSaving = true;
            this.editTotalVerpackungen = false;
            this.$emit('start-save');
            this.$refs.form.validate().then((result) => {
                if(!result.valid) {
                    this.isSaving = false;
                    return;
                }
                this.showTotalVerpackungen = false;
                this.apiClient.saveTotalVerpackungen(this.verpackungId, this.verpackung.totalVerpackungen)
                    .then((result) => {
                        this.verpackung = result.verpackung;
                        this.isSaving = false;
                        this.$emit('saved', this.verpackung);
                    })
                    .catch(() => {
                        this.isSaving = false;
                        this.$emit('saved', this.verpackung);
                    });
            });
        }
    }
});