import { createApp } from 'vue/dist/vue.esm-bundler';
import { createRouter, createWebHistory } from 'vue-router';
import { createI18n } from 'vue-i18n'
import ComponentFactory from "../factory/component.factory";
import Bottle from "bottlejs";
import routes from "../../../../config/routes";
import messagesDeDe from './../../../../messages/de-DE.json';
import messagesEnGb from './../../../../messages/en-GB.json';

export default class VueAdapter {
    /**
     *
     * @param {Application} application
     */
    constructor(application) {
        /**
         *
         * @type {Application}
         * @private
         */
        this._application = application;
        this._components = {};
    }

    startVue() {
        const app = createApp({
            template: `<packlab-app />`
        });
        const serviceContainer = this._application.getContainer('service');
        const serviceNames = Bottle.list(serviceContainer);
        const vueStore = this._application.getContainer('vueStore');


        for(let i in serviceNames) {
            app.provide(serviceNames[i], serviceContainer[serviceNames[i]]);
        }
        app.use(vueStore);
        this._createI18n(app);
        this._createComponents(app);


        this._createRouter(app);
        app.mount('#app');
    }

    _createRouter(app) {
        const parsedRoutes = this._getRoutes(app);
        const router = createRouter({
            history: createWebHistory(),
            routes: parsedRoutes,
            duplicateNavigationPolicy: 'reload'
        });

        app.use(router);
    }

    _getRoutes(app) {
        const parsedRoutes = [];
        for(let r in routes) {
            let route = routes[r];
            route.component = this._components[route.component];
            parsedRoutes.push(route);
        }
        return parsedRoutes;
    }

    _createI18n(app) {
        const messages = {
            'de-DE': messagesDeDe,
            'en-GB': messagesEnGb
        };
        const datetimeFormats = {
            'de-DE': {
                date: {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                },
                datetime: {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit'
                }
            },
            'en-GB': {
                date: {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                },
                datetime: {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit'
                }
            }
        };
        const numberFormats = {
            'de-DE': {
                integer: {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                },
                currency: {
                    style: 'currency',
                    currency: 'EUR',
                    currencyDisplay: 'symbol'
                },
                weight: {
                    style: 'decimal',
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3
                },
                weight0: {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                },                
                percent: {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                percent1: {
                    style: 'decimal',
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1
                },
                percent3: {
                    style: 'decimal',
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3
                }
            },
            'en-GB': {
                integer: {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                },
                currency: {
                    style: 'currency',
                    currency: 'EUR',
                    currencyDisplay: 'symbol'
                },
                weight: {
                    style: 'decimal',
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3
                },
                weight0: {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                },   
                percent: {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                percent1: {
                    style: 'decimal',
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1
                },
                percent3: {
                    style: 'decimal',
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3
                }
            }
        };
        this._vueI18n = createI18n({
            locale: this._application.getLocale(),
            messages,
            datetimeFormats,
            numberFormats,
            missingWarn: false, 
            fallbackWarn: false,
            silentTranslationWarn: true,
        });
        app.use(this._vueI18n);
    }

    _createComponents(app) {
        const componentRegistry = ComponentFactory.getComponentRegistry();
        const componentNames = [ ...componentRegistry.keys() ];
        for(let k in componentNames) {
            this._components[componentNames[k]] = ComponentFactory.build(componentNames[k]);
            app.component(componentNames[k], this._components[componentNames[k]]);
        }
    }
}
