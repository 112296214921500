import template from "./customer-settings-page.html.twig";
import { Form} from "vee-validate";

Packlab.Component.register('customer-settings-page', {
    template,

    components: { Form },

    mixins: [ 'notifications' ],
    inject: [ 'apiClient' ],

    data() {
        return {
            isLoading: false,
            isSaving: false,
            settings: null
        }
    },

    created() {
        this.loadSettings();
    },

    methods: {
        loadSettings() {
            this.apiClient.getCustomerSettings()
                .then((result) => {
                    this.settings = result.settings;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
        },
        save() {
            this.$refs.form.validate().then((result) => {
                if(!result.valid) {
                    return;
                }
                this.isSaving = true;
                this.apiClient.saveCustomerSettings(this.settings).then(() => {
                    this.publishInfo('info', this.$t('Einstellungen gespeichert'));
                }).catch(() => {
                    this.publishError('error'. this.$t('Einstellungen konnten nicht gespeichert werden'));
                }).finally(() => {
                    this.isSaving = false;
                });
            });
        }
    }
});
