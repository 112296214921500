import template from "./verpackung-herstellerspezifikationen-upload.html.twig";
import { Form, Field, ErrorMessage } from "vee-validate";

Packlab.Component.register('verpackung-herstellerspezifikationen-upload', {
    template,

    mixins: [ 'form-validation' ],

    components: { Form, Field, ErrorMessage },
    props: {
        verpackung: {
            type: Object,
            required: true
        },
        isReadonly: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        hasHerstellerspezifikationen() {
            return this.herstellerspezifikationen.length > 0;
        },
        herstellerspezifikationen() {
            if(this.verpackung && this.verpackung.herstellerspezifikationen) {
                return this.verpackung.herstellerspezifikationen.filter((file) => !(file.removeFile || false));
            }
            return [];
        }
    },

    data() {
        return {
            selectedFile: null,
            newFile: {
                description: '',
                file: {}
            },
            isUploading: false,
            showUploadFileModal: false,
            showRemoveFileModal: false
        }
    },

    methods: {
        onOpenUploadFileModal() {
            this.showUploadFileModal = true;
        },
        onHideUploadFileModal() {
            this.showUploadFileModal = false;
            this._resetNewFile();
        },
        onHideRemoveFileModal() {
            this.showRemoveFileModal = false;
            this.selectedFile = null;
        },
        onSaveFile() {
            this.$refs.uploadFileForm.validate().then((result) => {
                if(!result.valid) {
                    return;
                }

                this.verpackung.herstellerspezifikationen.push(this.newFile);
                this._resetNewFile();
                this.$refs.uploadFileModal.hide();
            });
        },
        removeFile(file) {
            this.selectedFile = file;
            this.showRemoveFileModal = true;
        },
        onRemoveFile() {
            this.selectedFile.removeFile = true;
            this.$refs.removeFileModal.hide();
        },
        _resetNewFile() {
            this.newFile = {
                description: '',
                file: {}
            };
        }
    }
});