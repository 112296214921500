import ModelBase from "./model-base";
import Verpackung from "./verpackung";
import ConditionResolver from "../../../utils/condition-resolver";
import { isEmpty } from "../../../utils/utils";

export default class VerpackungTyp extends ModelBase {
    constructor(verpackungKombi) {
        super();
        this.create();
        this.isKombiDraft = false;
        this.kombiDraftVerpackung = null;
        this.verpackungKombi = verpackungKombi;
        this.verpackungen = [];
    }

    load(json, ruleset) {
        this.create();

        this.isKombiDraft = json.isKombiDraft || false;

        this.isKombi = json.isKombi;
        this.name = json.isKombi ? json.name : '';
        this.verpackungTypKey = json.verpackungTypKey;

        if(json.verpackungTypKey === 'custom') {
            this.setCustomVerpackungTyp();
        } else {
            const rulesetVerpackungTyp = ruleset.getVerpackungTyp(json.verpackungTypKey);
            const rulesetVerpackungTypGruppe = rulesetVerpackungTyp ? ruleset.getVerpackungTypGruppe(rulesetVerpackungTyp.verpackungTypGruppe) : null;
            const mainVerpackungJson = json.verpackungen.filter((verpackung) => this.verpackungKombi?.verpackungId && verpackung.id == this.verpackungKombi?.verpackungId || (!this.verpackungKombi?.verpackungId && (verpackung.verpackungIndex == 0 || verpackung.verpackungIndex == 1)) )[0] || null;
            if(mainVerpackungJson) {
                this.defaultWertstoff = mainVerpackungJson.wertstoff;
            }
            if(rulesetVerpackungTyp && rulesetVerpackungTypGruppe) {
                this.verpackungTypKey = json.verpackungTypKey;
                this.verpackungTypGruppeKey = rulesetVerpackungTyp.verpackungTypGruppe;
                rulesetVerpackungTypGruppe.key = rulesetVerpackungTyp.verpackungTypGruppe;
                this.setVerpackungTypGruppe(ruleset, rulesetVerpackungTypGruppe, json.filterValues, false);
            }
        }

        for(let v in json.verpackungen) {
            const verpackungJson = json.verpackungen[v];
            const verpackung = this.getVerpackung(verpackungJson.verpackungIndex);
            verpackung.load(verpackungJson, ruleset);
            if(this.isKombiDraft) {
                this.kombiDraftVerpackung = verpackung;
            }
        }
    }

    create(options = {}) {
        this.isKombi = false;
        this.isKombiDraft = options.isKombiDraft || this.isKombiDraft;
        this.createKombi = false;
        this.skipKombi = false;
        this.deleteKombi = false;
        this.name = options.name || this.name;
        this.verpackungTypKey = options.verpackungTypKey || null;
        this.verpackungTypGruppeKey = options.verpackungTypGruppeKey || null;
        this.verpackungTypGruppe = null;
        this.isCustomVerpackungTyp = false;
        this.materialGruppe = '';
        this.unterGruppe = '';
        this.materialFraktion = '';
        this.defaultWertstoff = null;
        this.hinweis = '';
        this.image = null;
        this.mainVerpackungIndex = 0;
        this.selectKomponenten = false;
        this.filterSelects = {};
        this.selectWertstoff = false;
        this.wertstoffeSelect = [];
        this.verpackungen = [];
    }

    validate(options = {}) {
        const result = {};
        for (let p in this.verpackungen) {
            const verpackung = this.verpackungen[p];
            if(options.verpackungId && options.verpackungId != verpackung.id) {
                continue;
            }
            if(!verpackung.hasSelectedKomponenten() && this.isCustomVerpackungTyp !== true) {
                continue;
            }
            const verpackungenResult = verpackung.validate(options);
            if (Object.keys(verpackungenResult).length > 0) {
                result[this.verpackungen[p].uid] = verpackungenResult;
            }
        }
        if(Object.values(result).length > 0) {
            return {
                verpackungen: result
            }
        }
        return {};
    }

    getJson() {
        const verpackungen = [];
        for (let p in this.verpackungen) {
            const verpackung = this.verpackungen[p];
            if (!verpackung.hasSelectedKomponenten()) {
                continue;
            }
            verpackungen.push(verpackung.getJson());
        }
        if(this.isKombiDraft && verpackungen.length === 0) {
            const kombiDraftVerpackung = new Verpackung(this);
            kombiDraftVerpackung.create();
            kombiDraftVerpackung.name = this.name;
            verpackungen.push(kombiDraftVerpackung.getJson());
        }
        return {
            isKombi: this.isKombi,
            isKombiDraft: this.isKombiDraft,
            createKombi: this.createKombi,
            deleteKombi: this.deleteKombi,
            verpackungTypKey: this.verpackungTypKey,
            verpackungTypGruppeKey: this.verpackungTypGruppeKey,
            filterValues: this.getVerpackungFilterValues(),
            verpackungen: verpackungen
        };
    }

    getEditableVerpackungen(verpackungId = null) {
        let verpackungen = this.verpackungen;
        if(verpackungId && this.isKombi) {
            verpackungen = verpackungen.filter((verpackung) => { return verpackungId === verpackung.id; });
        }
        return verpackungen;
    }

    getKomponenten(verpackungId = null) {
        const komponenten = {};
        for (let v in this.verpackungen) {
            const verpackung = this.verpackungen[v];
            if(verpackungId && verpackung.id !== verpackungId && !this.createKombi) {
                continue;
            }
            for(let key in verpackung.komponenten) {
                komponenten[key] = verpackung.komponenten[key];
            }
        }
        return komponenten;
    }

    hasSelectedKomponenten() {
        const komponenten = this.getKomponenten();
        for(let k in komponenten) {
            if(komponenten[k].selectable && komponenten[k].selected) {
                return true;
            }
        }
        return false;
    }

    setCustomVerpackungTyp(name, materialFraktion, wertstoff) {
        this.isCustomVerpackungTyp = true;
        this.selectKomponenten = true;
        this.name = name;

        this.getMainVerpackung().name = name;
        this.getMainVerpackung().materialFraktion = materialFraktion;
        this.getMainVerpackung().setWertstoff(wertstoff);
    }

    createDefaultKomponente(wertstoff, defaultName = null) {
        this.getMainVerpackung().createWeitereHauptKomponente(wertstoff, defaultName);
    }

    setVerpackungTypGruppe(ruleset, verpackungTypGruppe, filterValues = {}, setHauptKomponenteHauptWertstoff = true) {
        this.verpackungTypGruppeKey = verpackungTypGruppe.key;
        this.verpackungTypGruppe = verpackungTypGruppe;
        this.mainVerpackungIndex = this.verpackungTypGruppe && this.verpackungTypGruppe.isKombi ? 1 : 0;
        this.isCustomVerpackungTyp = Object.values(verpackungTypGruppe.filterSelect).length === 0;
        if(this.verpackungTypGruppe) {
            this._setFilterSelect(ruleset, filterValues);
            this._setVerpackungen();
            this.updateFilterVisibility();
            this.updateAuswahlKomponenten(ruleset, setHauptKomponenteHauptWertstoff);
            this.image = this.verpackungTypGruppe.imageFilename;
        }
        this.wertstoff = null;
    }

    updateVerpackungTyp() {
        let createKombi = false;
        if(!this.verpackungTypGruppe) {
            return;
        }
        for(let v in this.verpackungen) {
            const verpackung = this.verpackungen[v];
            for (let k in verpackung.komponenten) {
                const komponente = verpackung.komponenten[k];
                if(komponente.selected && this.verpackungTypGruppe.isKombi && komponente.kombiPart != this.mainVerpackungIndex) {
                    createKombi = true;
                    break;
                }
            }
        }
        if(this.isKombi) {
            this.deleteKombi = !createKombi;
        } else {
            this.createKombi = createKombi;
        }
        this.getEmitter().emit('updateVerpackungTyp', this);
    }

    getVerpackungFilterValues() {
        const filterValues = {};
        for(let filter in this.filterSelects) {
            if(this.filterSelects[filter].visibleCondition !== true) {
                continue;
            }
            filterValues[filter] = this.filterSelects[filter].value;
        }
        return filterValues;
    }

    hasVerpackungKombiTypeGruppe() {
        return this.verpackungTypGruppe && this.verpackungTypGruppe.isKombi === true;
    }

    updateFilterVisibility() {
        for(let f in this.filterSelects) {
            const filterSelect = this.filterSelects[f];
            const conditionResolver = new ConditionResolver(this.getVerpackungFilterValues());
            const match = conditionResolver.resolveCondition(filterSelect.type.condition || null);
            if(filterSelect.options.length === 1) {
                filterSelect.value = match ? filterSelect.options[0].value : '';
            }
            filterSelect.visibleCondition = match;
        }
    }

    updateAuswahlKomponenten(ruleset, setHauptKomponenteHauptWertstoff = true) {
        if(isEmpty(this.verpackungTypGruppe)) {
            return;
        }
        const verpackungFilterValues = this.getVerpackungFilterValues();
        for(let v in verpackungFilterValues) {
            if(isEmpty(verpackungFilterValues[v])) {
                return;
            }
        }

        const matchingVerpackungTypen = Object.values(this.getMatchingVerpackungsTypen(ruleset.getVerpackungTypen()));
        this.updateAuswahlWertstoffe(ruleset);

        this.selectKomponenten = matchingVerpackungTypen.length === 1;
        if(!this.selectKomponenten) {
            return;
        }

        const matchingVerpackungTyp = matchingVerpackungTypen[0];
        if(isEmpty(this.name)) {
            this.name = matchingVerpackungTyp.title;
        }
        this._setVerpackungNames(matchingVerpackungTyp);

        this.verpackungTypKey = matchingVerpackungTyp.key;
        this.materialGruppe = matchingVerpackungTyp.materialGruppe;
        this.unterGruppe = matchingVerpackungTyp.materialUntergruppe;
        this.getMainVerpackung().materialFraktion = matchingVerpackungTyp.materialFraktion;
        this.getMainVerpackung().setWertstoff(matchingVerpackungTyp.wertstoff, setHauptKomponenteHauptWertstoff);
    }

    updateAuswahlWertstoffe(ruleset) {
        const matchingWertstoffe = Object.values(this.getMatchingWertstoffe(ruleset));
        this.wertstoffeSelect = {};
        if(matchingWertstoffe.length > 1) {
            this.selectWertstoff = this._showSelectWertstoff();
            for(let w in matchingWertstoffe) {
                const matchingWertstoff = matchingWertstoffe[w];
                this.wertstoffeSelect[matchingWertstoff.key] = matchingWertstoff;
            }
        }
    }

    getMatchingVerpackungsTypen(verpackungTypen) {
        const matchingVerpackungsTypen = {};
        if(this.verpackungTypKey && typeof verpackungTypen[this.verpackungTypKey] === 'object') {
            const verpackungTyp = verpackungTypen[this.verpackungTypKey];
            verpackungTyp.key = this.verpackungTypKey;
            return [ verpackungTyp ];
        }
        let verpackungWertstoff = this.getMainVerpackung() && this.getMainVerpackung().wertstoff !== null ? this.getMainVerpackung().wertstoff : this.defaultWertstoff;

        for(let key in verpackungTypen) {
            const verpackungTyp = verpackungTypen[key];
            if (verpackungTyp.verpackungTypGruppe != this.verpackungTypGruppeKey) {
                continue;
            }
            if(verpackungWertstoff != null && verpackungWertstoff != verpackungTyp.wertstoff && !isEmpty(verpackungTyp.wertstoff)) {
                continue;
            }
            if (!this._matchVerpackungTypeFilters(verpackungTyp)) {
                continue;
            }
            verpackungTyp.key = key;
            matchingVerpackungsTypen[key] = verpackungTyp;
        }
        return matchingVerpackungsTypen;
    }

    getMatchingWertstoffe(ruleset) {
        const matchingWertstoffe = {};
        const verpackungTypen = ruleset.getVerpackungTypen();
        for(let key in verpackungTypen) {
            const verpackungTyp = verpackungTypen[key];
            if (verpackungTyp.verpackungTypGruppe != this.verpackungTypGruppeKey) {
                continue;
            }
            if (!this._matchVerpackungTypeFilters(verpackungTyp)) {
                continue;
            }
            const wertstoffKey = verpackungTyp.wertstoff;
            const wertstoff = ruleset.getWertstoff(wertstoffKey);
            if(!wertstoff) {
                matchingWertstoffe[wertstoffKey] = {
                    key: wertstoffKey,
                    title: wertstoffKey
                };
                continue;
            }
            matchingWertstoffe[wertstoffKey] = {
                key: wertstoffKey,
                title: wertstoff.titleAuswahl || wertstoff.title
            };
        }
        return matchingWertstoffe;
    }

    getMainVerpackung() {
        if(this.verpackungKombi?.verpackungId) {
            const mainVerpackung = this.verpackungen.filter((verpackung) => verpackung.id == this.verpackungKombi?.verpackungId)[0] || null;
            if(mainVerpackung) {
                return mainVerpackung;
            }
        }
        return this.getVerpackung(this.mainVerpackungIndex);
    }

    getVerpackung(verpackungIndex) {
        let verpackung = this.verpackungen.filter((part) => verpackungIndex == part.verpackungIndex)[0] || null;
        if(!verpackung) {
            verpackung = new Verpackung(this);
            if((verpackungIndex === 0 || verpackungIndex === 1) && this.kombiDraftVerpackung) {
                verpackung.id = this.kombiDraftVerpackung.id;
                verpackung.name = this.kombiDraftVerpackung.name;
            }
            verpackung.create({ verpackungIndex: verpackungIndex });
            this.verpackungen.push(verpackung);
        }
        return verpackung;
    }

    getVerpackungById(verpackungId) {
        return this.verpackungen.filter((part) => part.id == verpackungId)[0] || null;
    }

    getName() {
        return this.createKombi || this.isKombi ? this.name : this.getMainVerpackung().name;
    }

    _setVerpackungNames(matchingVerpackungTyp) {
        const name = isEmpty(this.name) ? matchingVerpackungTyp.title : this.name;
        for(let v in this.verpackungen) {
            const verpackung = this.verpackungen[v];
            if(!isEmpty(verpackung.name)) {
                continue;
            }
            if(this.verpackungTypGruppe.isKombi) {
                const hauptKomponente = Object.values(this.verpackungTypGruppe.komponenten).filter((komponente) => komponente.kombiPart === verpackung.verpackungIndex && komponente.hauptKomponente)[0] || null;
                verpackung.name = hauptKomponente ? name + ' - ' + hauptKomponente.title : name;
            } else {
                verpackung.name = name;
            }
        }
    }

    _setVerpackungen() {
        const isKombi = this.verpackungTypGruppe.isKombi;
        for(let komponenteKey in this.verpackungTypGruppe.komponenten) {
            const komponente = this.verpackungTypGruppe.komponenten[komponenteKey];
            const verpackungIndex = isKombi ? komponente.kombiPart : this.mainVerpackungIndex;
            const verpackung = this.getVerpackung(verpackungIndex);
            verpackung.createKomponente(komponenteKey, komponente);
        }
    }

    _setFilterSelect(ruleset, filterValues = {}) {
        const filterSelects = this.verpackungTypGruppe.filterSelect ?? [];
        this.filterSelects = {};
        for(let key in filterSelects) {
            const filterSelect = filterSelects[key];
            const options = this._getVerpackungsgruppeFilterOptions(ruleset, filterSelect);
            this.filterSelects[filterSelect.field] = {
                type: filterSelect,
                value: (filterValues[filterSelect.field] || ""),
                options: options,
                visibleCondition: typeof filterValues[filterSelect.field] === "undefined" ? null : true
            };
        }
    }

    _showSelectWertstoff() {
        const filterSelects = Object.values(this.filterSelects);
        if(Object.values(this.verpackungTypGruppe?.filterSelect || []).length === 0) {
            return true;
        }
        if(filterSelects.length === 0) {
            return false;
        }
        for(let f in filterSelects) {
            if(filterSelects[f].visibleCondition !== false && isEmpty(filterSelects[f].value)) {
                return false;
            }
        }
        return true;
    }

    _matchVerpackungTypeFilters(verpackungTyp) {
        const filterSelects = this.filterSelects;
        for(let key in filterSelects) {
            const filterSelect = filterSelects[key];
            const filterValue = filterSelect.value || null;
            if(!filterSelect.visibleCondition) {
                continue;
            }

            if(isEmpty(filterValue)) {
                return false;
            }

            if(filterSelect.type.fkType === 'enum') {
                if((verpackungTyp.enums || []).indexOf(filterSelect.value) === -1) {
                    return false;
                }
            } else {
                if(typeof verpackungTyp[key] === 'undefined') {
                    return false;
                }
                if(verpackungTyp[key].toString().toLowerCase() !== filterValue.toString().toLowerCase()) {
                    return false;
                }
            }
        }
        return true;
    }

    _getVerpackungsgruppeFilterOptions(ruleset, verpackungsgruppeFilter) {
        let options = [];
        if(verpackungsgruppeFilter.fkType === 'object') {
            switch(verpackungsgruppeFilter.object) {
                case 'recyclinginfrastrukturen':
                    options = this._getValueTitleObjectList(ruleset.getInfrastrukturen());
                    break;
                case 'lucidMaterialgruppen':
                    options = this._getValueTitleObjectList(ruleset.getLucidMaterialgruppen());
                    break;
                case 'materialGruppen':
                    options = this._getValueTitleObjectList(ruleset.getMaterialGruppen());
                    break;
                case 'materialUntergruppen':
                    options = this._getValueTitleObjectList(ruleset.getMaterialUntergruppen());
                    break;
                case 'wertstoffe':
                    options = this._getValueTitleObjectList(ruleset.getWertstoffe());
                    break;
                case 'materialFraktionen':
                    options = this._getValueTitleObjectList(ruleset.getMaterialFraktionen());
                    break;
                case 'verpackungsTypen':
                    options = this._getValueTitleObjectList(ruleset.getVerpackungTypen());
                    break;
                case 'verpackungTypGruppen':
                    options = this._getValueTitleObjectList(ruleset.getVerpackungTypGruppen());
                    break;

            }
        } else if(verpackungsgruppeFilter.fkType === 'enum') {
            options = this._getValueTitleObjectList(ruleset.getEnumValues(verpackungsgruppeFilter.object));
        }
        if(verpackungsgruppeFilter.valid) {
            options = options.filter((option) => {
                return Object.values(verpackungsgruppeFilter.valid).indexOf(option.value) >= 0
            });
        }
        return options;
    }

    _getValueTitleObjectList(source, titleField = 'title') {
        const list = [];
        for(let key in source) {
            list.push({
                value: key,
                title: source[key][titleField]
            });
        }
        return list;
    }
}